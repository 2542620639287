import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: black;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  max-width: 800px;
  margin: 20px 0;
`;

const AboutPage = () => {
  return (
    <>
      <GlobalStyle />
      <AboutContainer>
        <Title>About 0xDev Limited</Title>
        <Paragraph>
          0xDev Limited is a UK-registered company specializing in bespoke blockchain solutions. We are experts in Solidity smart contract development, offering tailored services that include contract creation, rigorous testing, seamless deployment, and comprehensive auditing.
        </Paragraph>
        <Paragraph>
          Our team is committed to delivering secure and efficient smart contracts that meet the unique needs of each client. Whether you require a complex decentralized application (DApp) or a simple token contract, 0xDev Limited provides end-to-end development services that ensure your project is a success from concept to deployment.
        </Paragraph>
        <Paragraph>
          Our auditing services are designed to identify potential vulnerabilities and optimize contract performance, ensuring that your blockchain solutions are robust, reliable, and ready for the future. At 0xDev Limited, we don't just build contracts—we build trust.
        </Paragraph>
        <Link to="/" style={{ color: 'white', marginTop: '20px', display: 'block' }}>Back to Home</Link>
      </AboutContainer>
    </>
  );
};

export default AboutPage;
