import React from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Element } from 'react-scroll';
import AboutPage from './AboutPage'; // Import the AboutPage component

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: black;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const pulseBrightness = keyframes`
  0% {
    filter: brightness(25%) hue-rotate(90deg);
  }
  50% {
    filter: brightness(50%) hue-rotate(90deg);
  }
  100% {
    filter: brightness(25%) hue-rotate(90deg);
  }
`;

const Container = styled.div`
  position: relative;
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

const Section = styled(Element)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  scroll-snap-align: start;
  opacity: 0;
  animation: ${fadeIn} 2s forwards;
  animation-delay: 0.5s;
`;

const MatrixEffect = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('https://img.itch.zone/aW1hZ2UvMTY1MjM4MC85NzIxODE5LmdpZg==/original/i5438N.gif') repeat-y;
    background-size: cover;
    animation:${pulseBrightness} 10s infinite;
  }
`;

const Subtitle = styled.p`
  font-size: 2rem;
  animation: ${fadeIn} 2s forwards;
  margin: 0 20px;
`;

const ContactButton = styled.a`
  padding: 10px 20px;
  margin-top: 20px;
  background: white;
  color: black;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    background: gray;
  }
`;

const App = () => {
  return (
    <>
      <Router>
        <GlobalStyle />
        <MatrixEffect />
        <Routes>
          <Route path="/about" element={<AboutPage />} />
          <Route
            path="/"
            element={
              <Container>
                <Section name="home">
                  <Subtitle>0xDev - Solidity Smart Contract Development & Deployment</Subtitle>
                  <Link to="/about" style={{ cursor: 'pointer', display: 'block', marginTop: '20px', color: 'white' }}>
                    Learn More About Us
                  </Link>
                </Section>
                <Section name="about">
                  <Subtitle>Hello Friend. We build the future of decentralized applications.</Subtitle>
                  <Link to="/services" style={{ cursor: 'pointer', display: 'block', marginTop: '20px', color: 'white' }}>
                    Our Services
                  </Link>
                </Section>
                <Section name="services">
                  <Subtitle>Smart Contracts, Audits, and DApp Development</Subtitle>
                  <Link to="/contact" style={{ cursor: 'pointer', display: 'block', marginTop: '20px', color: 'white' }}>
                    Get in Touch
                  </Link>
                </Section>
                <Section name="contact">
                  <Subtitle>Contact Us to Start Your Blockchain Project</Subtitle>
                  <ContactButton href="https://forms.gle/B1JcXz5Qc7AG9hmo7" target="_blank">
                    Get in Touch
                  </ContactButton>
                </Section>
              </Container>
            }
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
